import Image from "gatsby-image"

class ImageDelay extends Image {
  componentDidMount() {
    // call the parent class' componentDidMount method
    // to preserve existing behavior
    super.componentDidMount()

    console.log("componentDidMount")
    setTimeout(() => {
      console.log("window setTimeout")
      this.setState((state) => ({
        ...state,
        isVisible: true,
      }))
    }, 3000)
  }
}

export default ImageDelay
